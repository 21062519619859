/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 11 авг. 2020 г., 11:52:26
    Author     : andxbes
*/
@import "_variables.scss";
@import "_mixins.scss";
@import "_flex-grid.scss";
@import "_utils.scss";


.colored_ol{
    list-style-type: none;
    counter-reset: num;
    margin: 0 0 0 35px;
    padding: 15px 0 5px 0;
    /*font-size: 18px;*/
    li {
        position: relative;
        margin: 0 0 0 0;
        padding: 0 0 10px 0;
        &::before {
            content: counter(num) '.';
            counter-increment: num;
            display: inline-block;
            position: absolute;
            top: 0px;
            left: -26px;
            width: 20px;
            color: #BA3131;
            text-align: right;
            font-size: 13px;
            line-height: 16px;

        }

    }
}
.lending{
    h2{
        margin-bottom: 20px;
    }
    ul,ol{

    }
    &__table,
    table{
        /*border-color: #DFDFDF;*/
        td,th{
            border: 1px solid #DFDFDF;
            padding: 16px 10px 14px;
        }
        th,thead td{
            background: #F7F7F7;
        }
    }
    ol {
        @extend .colored_ol;
    }

}


.list-anchor{
    list-style-image: url(../images/icons/list-anchor.png);
}

.red-file-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(87.72deg, #BB3131 3.07%, #602323 94.62%);
    border-radius: 40px;
    &__text{
        display: flex;
        align-items: center;
        padding-left: 30px;
        a{

            color:white;
            font-family: Arial;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            border-bottom: none;

            /* identical to box height */
            text-decoration-line: underline;

            color: #FFFFFF;
            &:hover{
                color:  #FFFFFF;
                text-decoration: none;
                border-bottom: none;
            }

        }
        &::before{
            content: "";
            display: inline-block;
            width: 20px;
            height: 25px;
            margin-right: 10px;
            background-image: url(../images/down-ico.svg);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &__img{

    }
}

.like-checkboxes{
    /*list-style-image: url(../images/icons/like-checkboxes.png);*/
    list-style: none;
    list-style-type: none;
    counter-reset: num;
    margin: 0 0 0 40px;
    padding: 15px 0 5px 0;
    /*font-size: 18px;*/
    li {
        position: relative;
        margin: 0 0 0 0;
        padding: 0 0 10px 0;
        &::before {
            content: "";
            /*            content: counter(num) '.';
                        counter-increment: num;*/
            display: inline-block;
            position: absolute;
            top: 0px;
            left: -35px;
            width: 24px;
            height: 24px;
            color: #BA3131;
            text-align: right;
            font-size: 13px;
            line-height: 16px;
            background-image: url(../images/icons/like-checkboxes.png);
            background-repeat: no-repeat;

        }

    }

    li{
        padding-bottom: 10px;
    }

}
.lending-partners{
    position: relative;

    &__more-exist::after{
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 82.81%);
        height: 151px;
        width: 100%;
        z-index: 1;

    }
    &__more-exist &__more{
        display: none;

    }

    &__item{
        margin-top: 40px;
    }

    &__see-more{

        position: relative;
        z-index: 5;
        background: linear-gradient(180deg, #6C93B7 0%, #28649D 100%), #C4C4C4;
        box-shadow: 0px 10px 15px #8EA1B3;
        color: #FFFFFF;
        font-size: 13px;
        line-height: 15px;
        display: inline-flex;
        align-items: center;
        padding: 5px 14px 6px;
        cursor: pointer;
        border: none;
        &:hover{
            background: linear-gradient(0deg, #6C93B7 0%, #28649D 100%), #C4C4C4;
        }


        &::before{
            content: "";
            width: 8px;
            height: 4px;
            position: relative;
            display: inline-block;
            background-image: url(../images/icons/botton-down.svg);
            background-repeat: no-repeat;
            margin-right: 5px;
        }
    }
}

.sidebar-form{
    position: relative;
    box-sizing: border-box;
    padding: 18px 0;
    @include media-max(md){
        padding: 18px 15px;
    }
    &__title{
        font-family: Arial;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        /* identical to box height */
        letter-spacing: 0.05em;
        text-transform: uppercase;

        color:#666666;
        margin-bottom: 20px;

        &> span{
            color: #B7202D;
        }


    }
    &__fields{

    }
    &__field{
        margin-bottom: 20px;
        font-family: Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

        /* identical to box height */
        letter-spacing: 0.05em;

        color: #000000;
        input{
            display: block;
            width: 100%;
            appearance: none;
            background: #FFFFFF;
            border: 1px solid #AAA6A6;
            box-sizing: border-box;
            padding: 6px 12px;
            max-width: 100%;
        }

        textarea{
            @extend input;
        }
    }
    &__btn{
        padding: 4px 30px 7px;
        background: linear-gradient(180deg, #6C93B7 0%, #28649D 100%), #C4C4C4;
        font-family: Arial;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        color: #FFFFFF;
        margin-bottom: 14px;
        cursor: pointer;
        &:hover{
            background: linear-gradient(180deg,#28649D  0%, #6C93B7 100%), #C4C4C4;
        }
    }
    &__cound{
        font-family: Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 127%;

        /* or 127% */

        color: #999999;
    }
    label{
        display: block;
        margin-bottom: 10px;
    }
}

.bottom-form {
    position: relative;
    /*overflow: hidden;*/
    box-sizing: border-box;
    &__title{
        font-family: Arial;
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 81% ;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: #CC0000;
        margin-bottom: 10px;
    }
    /*    @extend .sidebar-form;*/
    &__groups{
        $padding: 15px;
        padding: 10px 0 ;
        background-image: url(/images/bottom-form.png);
        background-repeat: no-repeat;
        background-size: cover;

        position: relative;
        margin-left: -#{$padding};
        margin-right: -#{$padding};
        background-size: auto;
        @include media-min(md){
            /*background-position: ;*/
        }
        &> *{
            width: calc(100% - (#{$padding} * 2));
            @include media-min(sm){
                width: 34%;
            }
            padding: 0 #{$padding};

        }
        @include clear();

    }
    &__fields{
        float: left;
    }
    &__field{
        margin-bottom: 20px;
        font-family: Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

        /* identical to box height */
        letter-spacing: 0.05em;

        color: #000000;
        input{
            display: block;
            width: 100%;
            appearance: none;
            background: #FFFFFF;
            border: 1px solid #AAA6A6;
            box-sizing: border-box;
            padding: 6px 12px;
            background-image: none !important;
            text-indent: initial !important;
            max-width: 100%;
        }

        textarea{
            @extend input;
        }
        label{
            display: block;
            margin-bottom: 10px;
        }
    }
    &__btn{
        padding: 4px 30px 7px;
        background: linear-gradient(180deg, #6C93B7 0%, #28649D 100%), #C4C4C4;
        font-family: Arial;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        color: #FFFFFF;
        margin-bottom: 14px;
        cursor: pointer;
        &:hover{
            background: linear-gradient(180deg,#28649D  0%, #6C93B7 100%), #C4C4C4;
        }
    }
    &__cound{
        font-family: Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 125%;

        /* or 127% */

        color: #999999;
    }
}


.sidebar{
    &>*{
        margin-bottom: 20px;
    }
}
.news-wrp{
    @include clear();
}
li{

    &:first-of-type{
        .level1-link{
            margin-top: 20px; 
        }
    }
    .level1-link{
        border-top: 1px solid #bdc4d2;
        & + .submenu {
            display: none;
            & > li{
                float: none;
            }
        }



    }
    &.li-active > .submenu {
        display: block !important;
        border-top: 1px solid rgb(189, 196, 210); display: inline-block;
    }
}