/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 27 июл. 2020 г., 11:55:05
    Author     : andxbes
*/

/*text*/

@include text-aligin();
@each $mark, $size in $grid-breakpoints {
    /*    @media (min-width: ($size + $grid_padding * 2) ){*/
    @include media-min($mark){
        @include text-aligin($mark);
    }

}
@include d-none();

@include margin-bootom(130);

@include padding(50);


.text-red{
    color:#CC0000;
}
.text-black{
    color: #000000;
}

.box-shadow{
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}
.f-container {
    img{
        max-width: 100%;
        height: auto;
    }
}