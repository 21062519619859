/* @if map-has-key($breakpoints, $breakpoint) {
     @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
         @content;
     }
 }*/
@mixin media-min($breakpoint){
    @if map-has-key($grid-breakpoints, $breakpoint) {
        @media (min-width: (map-get($grid-breakpoints, $breakpoint))) {
            @content;
        }
    }   @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$grid-breakpoints` map.";
    }
}

@mixin media-max($breakpoint){
    @if map-has-key($grid-breakpoints, $breakpoint) {
        @media (max-width: (map-get($grid-breakpoints, $breakpoint) + $grid_padding * 2)) {
            @content;
        }
    }  @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$grid-breakpoints` map.";
    }

}

@mixin clear(){
    &::before,
    &::after {
        content: " ";
        display: table;
    }
    &::after {
        clear: both;
    }
}


/* end grid */

/*utils*/
@mixin text-aligin($mark:'') {
    $mark: if($mark !='' ,-#{$mark},$mark);
    .text#{$mark}-center{
        text-align: center !important;
    }
    .text#{$mark}-left{
        text-align: left !important;
    }
    .text#{$mark}-right{
        text-align: right !important;
    }
    .text#{$mark}-justify {
        text-align: justify  !important;
    }
}

@mixin d-none() {
    @each $mark, $size in $grid-breakpoints {
        $mark_class: if($mark !='' ,-#{$mark},$mark);
        @include media-max($mark){
            .d#{$mark_class}-none-down{
                display: none !important;
            }
        }
        @include media-min($mark){
            .d#{$mark_class}-none-up{
                display: none !important;
            }
        }
    }

}

@mixin margin-bootom($max_pixels: 100){
    @each $mark, $size in $grid-breakpoints {
        $mark_class: if($mark !='' ,-#{$mark},$mark);
        @include media-min($mark){
            @for $i from 1 through $max_pixels {
                @if ($i % 5) == 0{
                    .margin-bottom#{$mark_class}-#{$i}{
                        margin-bottom: #{$i}px !important;
                    }
                }
            }
        }
    }

}
@mixin padding($max_pixels: 100){
    @each $mark, $size in $grid-breakpoints {
        $mark_class: if($mark !='' ,-#{$mark},$mark);
        @include media-min($mark){
            @for $i from 1 through $max_pixels {
                @if ($i % 5) == 0{
                    .padding-top#{$mark_class}-#{$i}{
                        padding-top: #{$i}px !important;
                    }
                    .padding-left#{$mark_class}-#{$i}{
                        padding-left: #{$i}px !important;
                    }
                    .padding-right#{$mark_class}-#{$i}{
                        padding-right: #{$i}px !important;
                    }
                    .padding-bottom#{$mark_class}-#{$i}{
                        padding-bottom: #{$i}px !important;
                    }
                }
            }
        }
    }
}

@mixin float() {
    @each $mark, $size in $grid-breakpoints {
        $mark_class: if($mark !='' ,-#{$mark},$mark);
        @include media-max($mark){
            .float#{$mark_class}-right-down {
                float: right !important;
            }
            .float#{$mark_class}-left-down {
                float: left !important;
            }
            .float#{$mark_class}-none-down {
                float: none !important;
            }
        }
        @include media-min($mark){

            .float#{$mark_class}-right-up {
                float: right !important;
            }
            .float#{$mark_class}-left-up {
                float: left !important;
            }
            .float#{$mark_class}-none-up {
                float: none !important;
            }
        }
    }

}

/* end utils */