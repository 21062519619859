/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 4 авг. 2020 г., 09:36:48
    Author     : andxbes
*/

@mixin greed-size($property,$cols,$prefix) {
    @for $i from 1 through $cols {
        $size: (100% / $cols * $i);
        .#{$prefix}-col-#{$property}-#{$i} {
            width: #{$size};
        }
    }
}

@mixin f-push($property, $cols, $prefix) {
    @for $i from 0 through $cols {
        $size: (100% / $cols * $i);
        @if $property {
            .#{$prefix}-col-push-#{$property}-#{$i} {
                @if $i == 0 {
                    margin-left: auto;
                }@else{
                    margin-left: #{$size};
                }
            }

        }
    }
}
@mixin f-pull($property, $cols, $prefix) {
    @for $i from 0 through $cols {
        $size: (100% / $cols * $i);
        @if $property {
            .#{$prefix}-col-pull-#{$property}-#{$i} {
                @if $i == 0 {
                    margin-right: auto;
                }@else{
                    margin-right: #{$size};
                }
            }
        }
    }
}

@mixin set_greed($prefix,$cols,$padding,$args){
    [class*="#{$prefix}-col-"]{
        width: 100%;
        padding: 0 $padding;
        box-sizing: border-box;
        position: relative;
    }

    .#{$prefix}-container-fluid,
    .#{$prefix}-container{
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        box-sizing: border-box;
        position: relative;
    }

    [class*="#{$prefix}-col-"] > .#{$prefix}-container{
        margin-left: -$padding;
        margin-right: -$padding;
        padding-right: 0;
        padding-left: 0;

    }

    .#{$prefix}-container-fluid{
        width: 100%;
    }

    @each $size, $witdh in $args{

        @media (max-width: #{$witdh}){
            .#{$prefix}-d-none-#{$size}{
                display: none !important;
            }
        }

        @media (min-width: #{$witdh}){

            .#{$prefix}-d-none-from-#{$size}{
                display: none !important;
            }

            .#{$prefix}-justify-center-#{$size}{
                justify-content: center;
            }
            .#{$prefix}-justify-right-#{$size}{
                justify-content: flex-end;
            }

            .#{$prefix}-justify-left-#{$size}{
                justify-content: flex-start;
            }

            .#{$prefix}-aligin-center-#{$size}{
                align-items: center;
            }
            .#{$prefix}-aligin-start-#{$size}{
                align-items: flex-start;
            }

            .#{$prefix}-aligin-end-#{$size}{
                align-items: flex-end;
            }

            .#{$prefix}-container{
                @if $witdh > 0{
                    max-width: #{$witdh};
                }@else{
                    max-width: 100%;
                }
            }

            .nopadding-#{$size}{
                padding: 0;
            }

            @include greed-size($size,$cols,$prefix);
            @include f-push($size,$cols,$prefix);
            @include f-pull($size,$cols,$prefix);
        }
    }
}

@include set_greed("f",12,$grid_padding,$grid-breakpoints);

.f-container{
    img{
        margin: 0 0;
        float: none;
    }
}

.nopadding{
    padding-left: 0 !important;
    padding-right: 0 !important;
}